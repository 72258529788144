import { formatKeys } from './formatKeys'
import { tizenKeyForEvent } from './tizenKeyForEvent'
import { KeyEventState } from './types'
import { xboxKeyForEvent } from './xboxKeyForEvent'

export const subscribeToKeyEvent = ({ keyOrKeys, callback, options }: KeyEventState) => {
  const keys = formatKeys(Array.isArray(keyOrKeys) ? keyOrKeys : [keyOrKeys])
  const handler = (event: KeyboardEvent) => {
    const xboxKey = xboxKeyForEvent(event)
    const tizenKey = tizenKeyForEvent(event)
    const eventKeys: string[] = formatKeys([
      `${event?.key ?? 'Unidentified'}`,
      `${event?.code ?? 'Unidentified'} `,
      `${event?.which ?? event?.keyCode ?? -1}`,
      `${xboxKey?.name ?? 'Unidentified'}`,
      `${xboxKey?.code ?? -1}`,
      `${tizenKey?.name ?? 'Unidentified'}`,
      `${tizenKey?.code ?? -1}`,
    ])

    const matchedKeys = keys.filter((key) => {
      return eventKeys.some((str) => str === key)
    })
    const matches = matchedKeys.length > 0

    if (matches) {
      callback(event, matchedKeys)
    }
  }

  document.addEventListener('keyup', handler, options)
  const unsubscribe = () => {
    document.removeEventListener('keyup', handler)
  }
  return unsubscribe
}
