import { getWindowDeviceInfo } from '@grandstand-web/bally-web-shared/src/utils/getWindowDeviceInfo'
import { getDefaultDeviceKey, getDeviceKeyForEvent } from './getDeviceKeyForEvent'
import { DeviceKey } from './types'

const xboxKeys: DeviceKey[] = [
  { name: 'GamepadLeftThumbstickLeft', code: 37 },
  { name: 'GamepadLeftThumbstickUp', code: 38 },
  { name: 'GamepadLeftThumbstickRight', code: 39 },
  { name: 'GamepadLeftThumbstickDown', code: 40 },
  { name: 'GamepadDPadLeft', code: 205 },
  { name: 'GamepadDPadUp', code: 203 },
  { name: 'GamepadDPadRight', code: 206 },
  { name: 'GamepadDPadDown', code: 204 },
  { name: 'GamepadRightThumbstickLeft', code: 218 },
  { name: 'GamepadRightThumbstickUp', code: 215 },
  { name: 'GamepadRightThumbstickRight', code: 217 },
  { name: 'GamepadRightThumbstickDown', code: 216 },
  { name: 'GamepadA', code: 195 },
  { name: 'GamepadB', code: 27 },
  { name: 'GamepadX', code: 197 },
  { name: 'GamepadY', code: 198 },
  { name: 'GamepadRightShoulder', code: 199 },
  { name: 'GamepadLeftShoulder', code: 200 },
  { name: 'GamepadLeftTrigger', code: 201 },
  { name: 'GamepadRightTrigger', code: 202 },
]
export function xboxKeyForEvent(event: KeyboardEvent): DeviceKey {
  const fallback = getDefaultDeviceKey()
  try {
    const deviceInfo = getWindowDeviceInfo()
    if (deviceInfo.platform !== 'tv_xboxone' || event.key !== 'Unidentified') {
      // the key is already valid and shouldn't be matched with xboxKeys
      return fallback
    }
    return getDeviceKeyForEvent(event, xboxKeys)
  } catch (_) {
    return fallback
  }
}
