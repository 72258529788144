import { DetailPanelContext } from '@/contexts/DetailPanelContext'
import { HeadingLabel, MetaDataLabel, SubheadingLabel } from '@/styles/fonts'
import { UserServiceContext } from '@grandstand-web/bally-web-shared/src/services/user/UserService'
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation'
import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'
import styled from 'styled-components'
import LiveBanner from '../banners/LiveBanner'
import DetailPanelButton from '../primitives/detail-panel-button'

interface TextLabelProps {
  fontSize: string
}

interface PanelProp {
  isDetailPanelShowing: boolean
}

const PanelWrapper = styled.div<{ show?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  display: ${({ show }) => (show ? 'flex' : 'none')};
  justify-content: flex-end;
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
`

const DetailPanelCover = styled.div<PanelProp>`
  visibility: ${({ isDetailPanelShowing }) => (isDetailPanelShowing ? 'visible' : 'hidden')};
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: black;
  opacity: ${({ isDetailPanelShowing }) => (isDetailPanelShowing ? '0.5' : 0)};
  transition: opacity 0.5s, visibility 0.5s;
`

const DetailPanelWrapper = styled.div<PanelProp>`
  position: relative;
  z-index: 2;
  height: 100%;
  width: 691px;
  background-color: var(--surface-dark);
  opacity: ${({ isDetailPanelShowing }) => (isDetailPanelShowing ? '1' : 0)};
  visibility: ${({ isDetailPanelShowing }) => (isDetailPanelShowing ? 'visible' : 'hidden')};
  display: flex;
  flex-direction: column;
  gap: 64px 0;
  padding: 64px 96px;
  transition: opacity 0.5s, visibility 0.5s;
`

const DetailPanelInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px 0;
`

const DetailPanelTitle = styled(HeadingLabel)<TextLabelProps>`
  color: var(--on-dark);
`

const DetailPanelDescription = styled(SubheadingLabel)`
  color: var(--on-dark);
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px 0;
  width: 100%;
`

const Captions = styled.div`
  display: flex;
  & > * {
    padding-right: 24px;
  }
`

const Caption = styled(MetaDataLabel)`
  color: var(--on-dark);
`

export default function DetailPanel() {
  const { hideDetailPanel, contentUrl, videoObject, isDetailPanelShowing, videoId, requiresCouchRights } =
    useContext(DetailPanelContext)

  const { checkin, couchrightsWarning } = useContext(UserServiceContext)

  // if requiresCouchRights, then we should checkin
  useEffect(() => {
    if (requiresCouchRights) {
      checkin()
    }
  }, [requiresCouchRights, checkin])
  const router = useRouter()
  const { setFocus, focusKey, ref, getCurrentFocusKey } = useFocusable({
    focusKey: 'DETAIL_PANEL',
    isFocusBoundary: true,
  })

  useEffect(() => {
    const currentKey = getCurrentFocusKey()
    setFocus('TOP_BUTTON')
    return () => {
      setFocus(currentKey)
    }
  }, [setFocus, getCurrentFocusKey])

  // create an onClick handler that will re-route to couchrights, video, OR video with fromBeginning=true

  if (!isDetailPanelShowing || !videoObject) {
    return null
  }
  const handler =
    (fromBeginning: boolean = false) =>
    () => {
      const next = fromBeginning ? `/video/${videoId}?t=0` : `/video/${videoId}`
      if (requiresCouchRights && couchrightsWarning?.show) {
        router.replace(`/couchrights?next=${next}`).then(() => {
          hideDetailPanel()
        })
      } else {
        router.push(next).then(() => {
          hideDetailPanel()
        })
      }
    }

  const handleWatchPress = (next?: string) => {
    if (!next) {
      console.error('No contentUrl provided')
      return
    }
    if (requiresCouchRights && couchrightsWarning?.show) {
      router.replace(`/couchrights?next=${next}`).then(() => {
        hideDetailPanel()
      })
    } else {
      router.push(next).then(() => {
        hideDetailPanel()
      })
    }
  }
  const { title, captions, isLive, description, url } = videoObject

  return (
    <PanelWrapper show={isDetailPanelShowing}>
      <DetailPanelCover isDetailPanelShowing={isDetailPanelShowing} />
      <DetailPanelWrapper isDetailPanelShowing={isDetailPanelShowing}>
        <DetailPanelInfo>
          {!isLive && captions?.length && (
            <Captions>
              {captions.map((caption, index) => (
                <Caption fontSize="xl" key={index}>
                  {caption}
                </Caption>
              ))}
            </Captions>
          )}
          {isLive && <LiveBanner placement="detailPanel" />}
          <DetailPanelTitle fontSize="6">{title}</DetailPanelTitle>
          {description ? <DetailPanelDescription fontSize="4">{description}</DetailPanelDescription> : null}
        </DetailPanelInfo>
        <FocusContext.Provider value={focusKey}>
          {isLive && (
            <ButtonsWrapper ref={ref}>
              <DetailPanelButton
                focusKey="TOP_BUTTON"
                buttonTitle="WATCH"
                url={url!}
                videoId={videoId}
                onEnterPress={() => handleWatchPress(contentUrl)}
                axeLabel={`Watch live`}
              />
            </ButtonsWrapper>
          )}
        </FocusContext.Provider>
      </DetailPanelWrapper>
    </PanelWrapper>
  )
}
