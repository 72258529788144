import { ConvivaConstants, valueof } from '@convivainc/conviva-js-coresdk'
/**
 * ConfigError class: represents errors related to configuration.
 * This class extends the built-in Error class.
 */
export class ConfigError extends Error {
  /**
   * @param message - Optional error message
   */
  constructor(message?: string) {
    super(message)
    this.name = 'ConfigError' // Specify the name for this type of error.

    // Set the prototype explicitly to maintain the correct prototype chain when transpiled to ES5
    Object.setPrototypeOf(this, ConfigError.prototype)

    // This line helps maintain a proper stack trace in V8-based environments (Node.js and most browsers).
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ConfigError)
    }
  }
}
/**
 * AuthDeviceError class: represents errors related to AuthDeviceResponse.
 * This class extends the built-in Error class.
 */
export class AuthDeviceError extends Error {
  /**
   * @param message - Optional error message
   */
  constructor(message?: string) {
    super(message)
    this.name = 'AuthDeviceError' // Specify the name for this type of error.

    // Set the prototype explicitly to maintain the correct prototype chain when transpiled to ES5
    Object.setPrototypeOf(this, AuthDeviceError.prototype)

    // This line helps maintain a proper stack trace in V8-based environments (Node.js and most browsers).
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AuthDeviceError)
    }
  }
}
/**
 * APIError class: a general class for all API related errors.
 * This class extends the built-in Error class and adds a statusCode property.
 */
export class APIError extends Error {
  statusCode: number

  /**
   * @param message - Optional error message
   * @param statusCode - Optional HTTP status code, defaults to 500
   */
  constructor(message?: string, statusCode?: number) {
    super(message)
    this.name = 'APIError' // Specify the name for this type of error.
    this.statusCode = statusCode ?? 500 // Default status code to 500 if none is provided.

    // Set the prototype explicitly to maintain the correct prototype chain when transpiled to ES5
    Object.setPrototypeOf(this, APIError.prototype)

    // This line helps maintain a proper stack trace in V8-based environments (Node.js and most browsers).
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APIError)
    }
  }
}

/**
 * ClientError class: represents API errors caused by the client (4XX HTTP status codes).
 * This class extends APIError.
 */
export class ClientError extends APIError {
  /**
   * @param message - Optional error message
   * @param statusCode - Optional HTTP status code, defaults to 400
   */
  constructor(message?: string, statusCode?: number) {
    super(message, statusCode ?? 400) // Pass the error message to the parent constructor and specify a status code of 400.
    this.name = 'ClientError' // Specify the name for this type of error.

    // Set the prototype explicitly to maintain the correct prototype chain when transpiled to ES5
    Object.setPrototypeOf(this, ClientError.prototype)

    // This line helps maintain a proper stack trace in V8-based environments (Node.js and most browsers).
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ClientError)
    }
  }
}

/**
 * ServerError class: represents API errors caused by the server (5XX HTTP status codes).
 * This class extends APIError.
 */
export class ServerError extends APIError {
  /**
   * @param message - Optional error message
   * @param statusCode - Optional HTTP status code, defaults to 500
   */
  constructor(message?: string, statusCode?: number) {
    super(message, statusCode ?? 500) // Pass the error message to the parent constructor and specify a status code of 500.
    this.name = 'ServerError' // Specify the name for this type of error.

    // Set the prototype explicitly to maintain the correct prototype chain when transpiled to ES5
    Object.setPrototypeOf(this, ServerError.prototype)

    // This line helps maintain a proper stack trace in V8-based environments (Node.js and most browsers).
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ServerError)
    }
  }
}

/**
 * NetworkError class: represents API errors caused by network issues.
 * This class extends APIError.
 */
export class NetworkError extends APIError {
  /**
   * @param message - Optional error message
   */
  constructor(message?: string) {
    super(message) // Pass the error message to the parent constructor. No status code is specified as network errors usually occur before a status code can be determined.
    this.name = 'NetworkError' // Specify the name for this type of error.

    // Set the prototype explicitly to maintain the correct prototype chain when transpiled to ES5
    Object.setPrototypeOf(this, NetworkError.prototype)

    // This line helps maintain a proper stack trace in V8-based environments (Node.js and most browsers).
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NetworkError)
    }
  }
}

// ErrorDetails & ErrorButton
// For components displaying Errors in both web and tv
export class ErrorButton {
  title: string
  style: 'primary' | 'alt' | 'on-image' | 'live'
  size: 'min' | 'xs' | 'sm' | 'md' | 'lg'
  focusKey?: string
  action: () => any

  /**
   *
   * @param title - Button title
   * @param style - Optional button style: primary | alt | on-image | live
   * @param size - Optional button size: min | xs | sm | md | lg
   * @param focusKey - Focus key
   * @param action - Button action to call onClick (web) or onEnter (tv)
   */
  constructor({
    title,
    style,
    size,
    focusKey,
    action,
  }: {
    title: string
    style?: 'primary' | 'alt' | 'on-image' | 'live'
    size?: 'min' | 'xs' | 'sm' | 'md' | 'lg'
    focusKey?: string
    action?: () => any
  }) {
    this.title = title
    this.style = style ?? 'alt'
    this.size = size ?? 'sm'
    this.focusKey = focusKey
    this.action = action ?? (() => {})
  }
}

export type ConvivaErrorSeverity = valueof<ConvivaConstants['ErrorSeverity']>

export type ExtraErrorOptions = {
  showHelpLink?: boolean
}

export class ErrorDetails {
  title: string
  message: string
  status: number
  code: string
  severity: ConvivaErrorSeverity
  buttons?: ErrorButton[]
  tvButtons?: ErrorButton[]
  extraOptions?: ExtraErrorOptions
  /**
   *
   * @param title - Error title
   * @param message - Error message
   * @param buttons - Error buttons
   * @param tvButtons - Error buttons for TV
   * @param status - Error status code
   * @param code - Error code
   */
  constructor({
    title,
    message,
    buttons,
    tvButtons,
    status,
    code,
    severity,
    extraOptions,
  }: {
    title: string
    message: string
    status: number
    code: string
    buttons?: ErrorButton[]
    tvButtons?: ErrorButton[]
    severity?: ConvivaErrorSeverity
    extraOptions?: ExtraErrorOptions
  }) {
    this.title = title
    this.message = message
    this.status = status
    this.code = code
    this.buttons = buttons ?? []
    this.tvButtons = tvButtons ?? []
    this.severity = severity ?? 0
    this.extraOptions = extraOptions
  }
}
