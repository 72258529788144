import { UiContext } from '@/contexts/UiContext'
import { HeadingLabel, PrimaryLabel } from '@/styles/fonts'
import { isNotInProd } from '@grandstand-web/bally-web-shared/src/utils/envUtils'
import useKeyEvent from '@grandstand-web/device-input/src/useKeyEvent'
import { keysForEvent } from '@grandstand-web/device-input/src/utils/keysForEvent'
import { DeviceKey } from '@grandstand-web/device-input/src/utils/types'
import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { InteractiveDebugMenu } from './InteractiveDebugMenu'
const Container = styled.div`
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: var(--surface);
  position: fixed;
  color: white;
  padding: 64px;
  overflow-wrap: break-word;
  overflow-y: auto;
  text-align: left;
  font-size: 24px;
  font-family: var(--default-font);
  display: flex;
  flex-direction: column;
  gap: 32px 0;
`

const DeviceKeys = styled.div`
  padding: 16px 0;
  display: grid;
  gap: 4px 0;
  grid-template-columns: auto auto 1fr;
  color: var(--on-background);
  & > * {
    padding: 0 64px 4px 0;

    // every column besides first > 1 should be monospace
  }
`

type DeviceKeyWithDevice = DeviceKey & { device: string }

const DebugMenu = () => {
  const { isDebugMenuVisible: isVisible, setIsDebugMenuVisible: setVisible } = useContext(UiContext)
  const [deviceKeys, setDeviceKeys] = useState<DeviceKeyWithDevice[]>([])
  const isProd = !isNotInProd()

  // if isVisible && isProd, hide the debug menu
  useEffect(() => {
    if (!isVisible) {
      return
    }
    if (isProd) {
      setVisible(false)
    }
    return () => {}
  }, [isVisible, setVisible, isProd])

  const handleToggle = (_: KeyboardEvent) => {
    if (isProd) {
      return
    }
    setVisible((prev) => !prev)
  }

  // reload the page
  const handleReload = (_: KeyboardEvent) => {
    window.location.reload()
  }

  // listen for F1/GamepadY to toggle the debug menu
  useKeyEvent(['F1', 'GamepadY'], handleToggle)

  // listen for F2/GamepadX to reload the page
  useKeyEvent(['F2', 'GamepadX'], handleReload)

  useEffect(() => {
    if (isProd) {
      return
    }
    const handleKey = (evt: KeyboardEvent) => {
      evt.preventDefault()
      const deviceKeys = keysForEvent(evt).map((key, index) => ({ ...key, device: ['Base', 'Tizen', 'Xbox'][index] }))
      // const key = getDeviceKeyForEvent(evt, deviceKeys)
      setDeviceKeys(deviceKeys)
      return false
    }
    addEventListener('keyup', handleKey, { capture: true })
    return () => {
      removeEventListener('keyup', handleKey)
    }
  }, [isProd])
  // spacer should be 4 non-breaking spaces

  if (!isVisible || isProd) {
    return <></>
  }
  return (
    <Container>
      <HeadingLabel fontSize="8">Debug Menu</HeadingLabel>
      <div>
        <PrimaryLabel fontSize="xl">Last Key Pressed</PrimaryLabel>
        {!deviceKeys.length ? null : (
          <DeviceKeys>
            <div>
              <b>Device</b>
            </div>
            <div>
              <b>Key</b>
              <br />
            </div>
            <div>
              <b>KeyCode</b>
            </div>

            {deviceKeys.map(({ device, name, code }) => (
              <>
                <div>{device}</div>
                <div>
                  <code>{name}</code>
                </div>
                <div>
                  <code>{code}</code>
                </div>
              </>
            ))}
          </DeviceKeys>
        )}
      </div>
      <div>
        <PrimaryLabel fontSize="xl">Current URL: {window.location.href}</PrimaryLabel>
      </div>
      <InteractiveDebugMenu />
    </Container>
  )
}

export default DebugMenu
