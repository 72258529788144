import { useEffect, useRef } from 'react'
import { subscribeToKeyEvent } from '../utils/subscribeToKeyEvent'
import { KeyEventCallback, KeyEventState, KeyOrKeys } from '../utils/types'

const useKeyEvent = (keyOrKeys: KeyOrKeys, callback: KeyEventCallback, options?: AddEventListenerOptions) => {
  const ref = useRef<KeyEventState>({
    keyOrKeys,
    callback,
    options,
  })

  ref.current = { keyOrKeys, callback, options }

  useEffect(() => {
    const unsubscribe = subscribeToKeyEvent(ref.current)
    return () => {
      unsubscribe()
    }
  }, [])
}

export default useKeyEvent
