import { getWindowDeviceInfo } from '@grandstand-web/bally-web-shared/src/utils/getWindowDeviceInfo'
import { getDefaultDeviceKey, getDeviceKeyForEvent } from './getDeviceKeyForEvent'
import { getTizenSupportedKeys } from './registerTizenKeys'
import { DeviceKey } from './types'

export const tizenKeyForEvent = (event: KeyboardEvent): DeviceKey => {
  try {
    const deviceInfo = getWindowDeviceInfo()
    if (deviceInfo.platform !== 'tv_samsung') {
      return getDefaultDeviceKey()
    }
    const keysForEvent = getTizenSupportedKeys()
    return getDeviceKeyForEvent(event, keysForEvent)
  } catch (_) {
    return getDefaultDeviceKey()
  }
}
