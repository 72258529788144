import { DeviceKey } from './types'
declare global {
  interface Window {
    tizenSupportedKeys?: DeviceKey[]
  }
}
export const registerTizenKeys = async (): Promise<DeviceKey[]> => {
  if (typeof window === 'undefined') {
    return []
  }
  if (typeof window?.tizenSupportedKeys !== 'undefined') {
    return window.tizenSupportedKeys
  }
  window.tizenSupportedKeys = []
  try {
    const tizenCore = await import('tizen-common-web')
    if (!tizenCore.tvinputdevice) {
      return window.tizenSupportedKeys
    }

    const inputDevice = tizenCore.tvinputdevice
    const supportedKeys = inputDevice.getSupportedKeys().map((key) => ({ ...key } as DeviceKey))
    inputDevice.registerKeyBatch([
      'ColorF0Red',
      'ColorF1Green',
      'MediaPlay',
      'MediaPause',
      'MediaPlayPause',
      'MediaRewind',
      'MediaFastForward',
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
    ])
    window.tizenSupportedKeys = supportedKeys
    return window.tizenSupportedKeys
  } catch (_) {
    return window.tizenSupportedKeys
  }
}
export const getTizenSupportedKeys = (): DeviceKey[] => {
  if (typeof window === 'undefined') {
    return []
  }
  if (typeof window?.tizenSupportedKeys === 'undefined') {
    window.tizenSupportedKeys = []
  }
  return window.tizenSupportedKeys
}
