import { DeviceKey } from './types'
export const getKeyEventCode = (event: KeyboardEvent): number => {
  if (typeof event?.which === 'number') {
    return event.which
  }
  if (typeof event?.keyCode === 'number') {
    return event.keyCode
  }
  return -1
}

export const getKeyEventNames = (event: KeyboardEvent): string[] => {
  const eventNames = [event.code, event.key].filter((name) => name && name.length)
  return eventNames
}

export const getKeyEventName = (event: KeyboardEvent): string => getKeyEventNames(event)?.[0] ?? 'Unidentified'

export const getDefaultDeviceKey = (): DeviceKey => ({ name: 'Unidentified', code: -1 })
/**
 * Returns whether the event matches any of the keys
 * @param event The event to match
 * @param keys The keys to match
 */
export const getDeviceKeyForEvent = (event: KeyboardEvent, deviceKeys: DeviceKey[]): DeviceKey => {
  const eventCode = getKeyEventCode(event)
  // xbox key events
  // - event.key will always be 'Unidentified' (we should match xbox codes)
  const eventNames = [event.code, event.key].filter((name) => name && name.length > 0).map((name) => name.toLowerCase())

  const deviceKey =
    deviceKeys.find((key) => {
      if (eventCode === key.code) {
        return true
      }
      const name = key.name.toLowerCase()
      return eventNames.some((eventName) => eventName === name)
    }) ?? getDefaultDeviceKey()
  return deviceKey
}
