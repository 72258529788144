export const formatKeys = (keys: string[]) => {
  return keys.map((key) => {
    key = `${key}`.toLowerCase().replace(/\s+/g, ' ')
    if (key === ' ') {
      return key
    }
    // replace one or more whitespace characters with ''
    return key.replace(/\s+/g, '')
  })
}
