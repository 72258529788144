import { getKeyEventCode, getKeyEventName } from './getDeviceKeyForEvent'
import { tizenKeyForEvent } from './tizenKeyForEvent'
import { DeviceKey } from './types'
import { xboxKeyForEvent } from './xboxKeyForEvent'
export type DeviceKeysForEvent = [base: DeviceKey, tizen: DeviceKey, xbox: DeviceKey]
export const keysForEvent = (event: KeyboardEvent): [base: DeviceKey, tizen: DeviceKey, xbox: DeviceKey] => {
  const base: DeviceKey = {
    name: getKeyEventName(event),
    code: getKeyEventCode(event),
  }
  const tizen = tizenKeyForEvent(event)
  const xbox = xboxKeyForEvent(event)
  return [base, tizen, xbox]
}
