import ExitPanel from '@/components/Modal/ExitPanel'
import { ForceUpdate, useForceUpdate } from '@/hooks/useForceUpdate'
import { getWindowDeviceInfo } from '@grandstand-web/bally-web-shared/src/utils/getWindowDeviceInfo'
import useKeyEvent from '@grandstand-web/device-input/src/useKeyEvent'
import { registerTizenKeys } from '@grandstand-web/device-input/src/utils/registerTizenKeys'
import { useRouter } from 'next/router'
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { DetailPanelReference } from './DetailPanelContext'
// this global context is used to track ui
// for example, when Escape fires, it should...
// - if detailPanel is open, close it
// - if video controls visible, hide them
// - if captions button focused, focus the seek bar

export type UiContextType = {
  isVideoPage: boolean
  controlsVisible: boolean
  setControlsVisible: Dispatch<SetStateAction<boolean>>
  exitPanelVisible: boolean
  setExitPanelVisible: Dispatch<SetStateAction<boolean>>
  isDetailPanelShowing: boolean
  setIsDetailPanelShowing: Dispatch<SetStateAction<boolean>>
  isDebugMenuVisible: boolean
  setIsDebugMenuVisible: Dispatch<SetStateAction<boolean>>
  forceUpdate: ForceUpdate | null
}

const initialContext = (): UiContextType => ({
  isVideoPage: false,
  controlsVisible: false,
  setControlsVisible: () => {},
  exitPanelVisible: false,
  setExitPanelVisible: () => {},
  isDetailPanelShowing: false,
  setIsDetailPanelShowing: () => {},
  isDebugMenuVisible: false,
  setIsDebugMenuVisible: () => {},
  forceUpdate: null,
})

export const UiContext = createContext<UiContextType>(initialContext())
export type UiContextProviderProps = PropsWithChildren<{}>

export type KeyEventHandler = (event: KeyboardEvent) => void

export type ExitWarning = {
  visible: boolean
  exitable: boolean
}

export const exitRoutes = ['/home', '/hardwall', '/force-update']
export const videoRoutes = ['/video', '/live-event', '/game-details', '/vod']
const isRoute = (routerPathname: string, ...routes: string[]) => {
  routerPathname = routerPathname.toLowerCase()

  return routes.some((route) => {
    return routerPathname.indexOf(route) > -1 || window.location.pathname.indexOf(route) > -1
  })
}

export const getIsVideoPage = (routerPathname: string) => {
  return isRoute(routerPathname, ...videoRoutes)
}

export const UiContextProvider = ({ children }: UiContextProviderProps) => {
  const deviceInfo = getWindowDeviceInfo()
  const router = useRouter()

  const forceUpdate = useForceUpdate()

  const [isDebugMenuVisible, setIsDebugMenuVisible] = useState(false)

  const [isDetailPanelShowing, setIsDetailPanelShowing] = useState(false)
  const [exitPanelVisible, setExitPanelVisible] = useState<boolean>(false)
  const [controlsVisible, setControlsVisible] = useState(false)

  const isVideoPage = useMemo(() => getIsVideoPage(router.pathname), [router])

  // register tizen keys
  useEffect(() => {
    registerTizenKeys()
  }, [])
  // hide exit panel if not on /home, /hardwall, or if on xbox
  useEffect(() => {
    const isExitPage = isRoute(router.pathname, ...exitRoutes)
    const isXbox = deviceInfo.platform === 'tv_xboxone'
    if (exitPanelVisible && (!isExitPage || isXbox)) {
      setExitPanelVisible(false)
    }
  }, [exitPanelVisible, router.pathname, deviceInfo.platform])

  const handleEscapeRef = useRef(() => {})

  // ==============================
  //  KEYBOARD EVENT HANDLERS
  // ==============================
  useEffect(() => {
    const pathname = router.pathname
    const isExitPage = isRoute(pathname, ...exitRoutes)

    const shouldHideExitPanel = exitPanelVisible
    // only show exit panel on tizen devices (this means xbox + web will not show exit panel)
    const shouldShowExitPanel = deviceInfo.platform === 'tv_samsung' && isExitPage && !exitPanelVisible

    if (isVideoPage) {
      handleEscapeRef.current = () => {}
      return
    } else if (isDebugMenuVisible) {
      handleEscapeRef.current = () => setIsDebugMenuVisible(false)
    } else if (isDetailPanelShowing) {
      handleEscapeRef.current = () => setIsDetailPanelShowing(false)
    } else if (shouldHideExitPanel) {
      handleEscapeRef.current = () => setExitPanelVisible(false)
    } else if (shouldShowExitPanel) {
      handleEscapeRef.current = () => setExitPanelVisible(true)
    } else if (!isExitPage) {
      handleEscapeRef.current = () => router.back()
    } else {
      handleEscapeRef.current = () => {}
    }
  }, [
    isVideoPage,
    isDebugMenuVisible,
    isDetailPanelShowing,
    exitPanelVisible,
    router.pathname,
    router,
    deviceInfo.platform,
  ])

  useKeyEvent(['Escape', 'GamepadB'], () => {
    handleEscapeRef.current()
  })

  return (
    <UiContext.Provider
      value={{
        isVideoPage,
        controlsVisible,
        setControlsVisible,
        exitPanelVisible,
        setExitPanelVisible,
        isDetailPanelShowing,
        setIsDetailPanelShowing,
        isDebugMenuVisible,
        setIsDebugMenuVisible,
        forceUpdate,
      }}
    >
      <DetailPanelReference>{children}</DetailPanelReference>
      {exitPanelVisible ? <ExitPanel /> : null}
    </UiContext.Provider>
  )
}

export const useUi = () => {
  return useContext(UiContext)
}
